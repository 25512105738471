.contractor-show-container{
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;
}

.contractor-show-content{
    width: 70%;
    height: 190%;
    margin-top: 25px;
    display: flex;
    position: relative;
}

/* .contractor-show-content > * {
    margin: 10px;
    padding: 10px;
} */


.category-name:hover{
    cursor: pointer;
    text-decoration: underline;
    
}


.google-maps{
    height: 235px;
    width: 36em;
    padding-top: 2em;
    padding-right: 1em;
    padding-bottom: 1em;
    
}

.contractor-show-info-card{
    width: 40%;
    margin-bottom: 20px;
}

.contractor-show-right{
    position: sticky;
    z-index: 1;
    top: 70px;
    height: 70%;
    background-color: var(--secondary-background);
    padding: 40px;
    border: 2px solid var(--primary-border);
}

.contractor-show-right h3{
    font-weight: bold;
    color: var(--primary-border);
    margin-bottom: 20px;
}
.contractor-show-left{
    position: relative;
    width: 100%;
    min-width: 330px;
    height: 270%;
    background-color: var(--primary-background);
}

@media screen and (max-width: 1300px){
    .contractor-show-content{
        width: 90%;
    }
}

.contractor-show-image{
    border-radius: 50%;
    height: 120px;
    width: 120px;
    margin: 10px;
}

.contractor-show-container .header-name-container h3{
    font-size: 30px;
    width: 300px;
}

#category{
    font-size: 14px;
    color: var(--secondary-text);
}

#address{
    margin-top: 20px;
}

#bio{
    width: 300px;
    margin-top: 5px;
    color: var(--primary-gray);
}

select{
    width: 100%;
    background-color: var(--primary-white);
    height: 35px;
    border-radius: 4px;
    padding: 0 8px;
    font-size: 16px;
    margin: 15px 0;
    border: none;
}

.contractor-show-reviews-card {
    padding-right: 1em;
    padding-bottom: 1em;
}

.contractor-review {
    margin-right: 1em;
    margin-bottom: 1em;
    margin-top: 1em;
    padding: 1em;
    background-color: white;
    border-radius: 8px;
}

.contractor-add-review {
    margin-right: 2em;
    margin-bottom: 1em;
    margin-top: 1em;
    padding: 1em;
    background-color: white;
    border-radius: 8px;
}

.review-user-input {
    border: 1px solid white;
    border: 1px solid grey;
    border-radius: 5px;
    max-width: 80%;
    min-width: 50%;
    max-height: 10em;
    min-height: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
}

::placeholder {
    padding: 3px;
}

.review-info {
    padding: 0.5em;
}

.user-show-information {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
}

.info-card {
    margin-top: 0.5em
}

.review-actions {
    display: flex;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    min-width: 270px;
    gap: 10px;
}