.intro-container {
    background-color: var(--primary-background);
    display: flex;
    justify-content: center;
    padding: 2vw 0 0 10vw;
    padding-bottom: 5em;
}

.intro-content {
    flex: 1;
    color: #fff;
    width: 100px;
}

.intro-content > h1 {
    font-size: 80px;
    font-weight: 500;
}

.intro-content > p {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    font-size: 30px;
}

.intro-image-placeholder {
    flex: 1;
    display: flex;
    justify-content: center;
}



.hero-waves {
    background-color: var(--primary-background);
    display: block;
    width: 100%;
    height: 60px;
    z-index: 5;
    position: relative;
}

.wave1 use {
    animation: move-forever1 10s linear infinite;
    animation-delay: -2s;
}

.wave2 use {
    animation: move-forever2 8s linear infinite;
    animation-delay: -2s;
}

.wave3 use {
    animation: move-forever3 6s linear infinite;
    animation-delay: -2s;
}

@keyframes move-forever1 {
    0% {
    transform: translate(85px, 0%);
    }

    100% {
    transform: translate(-90px, 0%);
    }
}

@keyframes move-forever2 {
    0% {
    transform: translate(-90px, 0%);
    }

    100% {
    transform: translate(85px, 0%);
    }
}

@keyframes move-forever3 {
    0% {
    transform: translate(-90px, 0%);
    }

    100% {
    transform: translate(85px, 0%);
    }
}

.house-icon {
    height: 20em;
    width: 20em;
    animation: bobbing 4s ease-in-out infinite alternate;
}

@keyframes bobbing {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}
