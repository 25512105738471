.nav-bar-main {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 130px;
    height: 3.5em;
    margin: 0; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-background);
    z-index: 1000;
}

.nav-bar-main h1 {
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-size: 20px;
    cursor: pointer;
}

.house-icon-navbar {
    height: 2em;
}

.links-auth {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    border-radius: 4px;
}

.links-auth * {
    color: var(--primary-text);
    text-decoration: none;
}

.links-auth svg {
    height: 1.5em;
    width: 1.5em;
    transition: opacity 100ms ease-out;
    cursor: pointer;
    /* fill: #ff9437; */
}

.links-auth svg:hover {
    opacity: 0.5;
    fill: #ff9437;

}

.signup-nav-link {
    padding: 10px 5px 10px 10px
}

.signup-nav-link:hover {
    background-color: var(--tertiary-gray);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    cursor: pointer;
}

.login-nav-link {
    padding: 10px 10px 10px 5px;
    font-family: 'Poppins', sans-serif;
    border-radius: 7px;
}

.login-nav-link:hover {
    background-color: var(--tertiary-gray);
    border-radius: 8px;
    cursor: pointer;
}

#search-bar {
    height: 4em;
    width: 20em;
    border-radius: 8px;
    border: 1px solid black;
    padding-left: 10px;
}


.search-bar-container {
    position: relative;
    z-index: 100;
}

.search-results-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 4px;
    z-index: 100;
}

.search-result-item {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.3s;

}

.search-result-item:hover {
    background-color: #f5f5f5;
    /* Change background color on hover */
}

.search-result-item img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
}

.search-result-item span {
    font-size: 16px;
    /* Adjust font size as needed */
    color: #333;
    /* Adjust text color as needed */
}


/* .nav-bar-all {
    display: flex;
    flex-direction: column;
    align-items: center;
} */