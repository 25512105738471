.about-team-parent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.aboutUs-title {
  margin-bottom: 20px;
}

.about-us-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 1fr)); 
  gap: 20px;
  justify-content: center;
  max-width: 1400px; 
  margin: auto; 
}



.about-us-card {
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.about-us-imgs {
  width: 100%; 
  height: 300px;
  object-fit: cover; 
}

.about-us-info {
  padding: 15px;
}

.about-us-socials {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.about-us-socials img {
  width: 2em;
  height: auto;
}


@media (max-width: 768px) {
  .about-us-container {
    grid-template-columns: 1fr; 
  }
}
