.contractors-index-container {
    background-color: var(--secondary-background);
    display: flex;
    align-items: flex-start;
}

.google-maps-placeholder{
    width: 100%;
    height: 100vh;
    background-color: var(--primary-background);
    position: sticky;
    top: 0px;
}

.marker-label{
    transform: translate(-0%, -25%);
}