.contractor-section-index-item-container{
    height: 13em;
    min-width: 16em;
    width: 40%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: var(--primary-white);
    border-radius: 5px;
    padding: 10px;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
}

.contractor-section-index-item-container:hover {
    box-shadow: none;
    cursor:pointer;

}

.contractor-section-index-item-image-placeholder{
    height: 70px;
    width: 70px;
    border-radius: 100%;
    background-color: var(--secondary-background);
    display: relative;
}

.contractor-index-item-header-container{
    display: flex;
}

.reviews-tag{
    font-size: 11px;
}

.header-name-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 15px 0 0 15px;
}

.header-name-container h3 {
    width: 175px;
    font-weight: 500;
    color: var(--primary-text);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.index-item-body-container{
    margin: 20px 0 0 10px; 
    width: 10em
}

.index-item-body-container > *{
    margin: 10px 0;
}


.contractor-name {
    width: 6vw;
    min-width: 1em;
    margin-bottom: 0.5em;
}