.category-index-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 150px;
    text-align: center;
    padding: 5px;
    transition: background-color 0.2s, padding 0.2s;
    box-sizing: border-box;
    border-radius: 8px;
}

.category-index-item.selected,
.category-index-item:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: orange;
    padding: 5px;
    border-radius: 10px;
}

.category-index-item .icon-container {
    width: 3em;
    height: 3em;
    margin-bottom: 10px;
}

.category-index-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.index-item-title {
    color: var(--secondary-text);
    font-weight: 500;
    font-size: 16px;
    margin: 0;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .category-index-item {
        padding: 3px; /* Decrease padding */
        height: 80px; /* Adjust height if necessary */
        width: 120px; /* Adjust width if necessary */
    }

    .category-index-item .icon-container {
        width: 2.5em; /* Decrease icon size */
        height: 2.5em;
    }

    .index-item-title {
        font-size: 0.75rem; /* Decrease font size */
    }
}

/* Additional breakpoint for very small screens */
@media (max-width: 480px) {
    .category-index-item {
        padding: 2px; /* Further decrease padding */
        height: 70px; /* Further adjust height if necessary */
        width: 100px; /* Further adjust width if necessary */
    }

    .category-index-item .icon-container {
        width: 2em; /* Further decrease icon size */
        height: 2em;
    }

    .index-item-title {
        font-size: 0.7rem; /* Further decrease font size */
    }
}
