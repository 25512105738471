.signup-container {
    color: var(--primary-text);
    background-color: var(--primary-background);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3em;
    width: 100vw;
    height: 100vh;
}

.birthday {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-top: 0.5em;
}

.birthday select{
    height: 35px;
    width: 7em;
    border-radius: 8px;
    border: 1px solid var(--primary-text);
}

.create-account {
    font-size: 29px;
    margin-bottom: 1em;
}

.signup-input {
    height: 2em;
    width: 20em;
}

.session-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 20px;
    color: var(--primary-text);
}

.signup-button {
    width: 5em;
    margin-top: 1em;
}

.signup-click {
    color: black;
    width: 20.5em;
    height: 3em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

label {
    margin-bottom: 0.5em;
    width: 10em;
}

.errors {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: 15px;
}

.redirect-user-accounts {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 16em;
}

.login-link {
    background: none;
    border: none;
    color: var(--link);
    cursor: pointer;
    margin: 0;
    padding: 0;
    padding-left: 0.2em;
}

.login-link:hover {
    text-decoration: underline;
}

.signup-click:disabled,
.signup-click.disabled {
  background-color: gray;
  color: white;
  cursor: not-allowed;
}
