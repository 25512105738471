.main-search-bar-container{
    width: 100%;
    height: 15vh;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.search-bar-content{
    width: 30%;
    height: 40px;
    background-color: var(--primary-white);
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
}

.search-icon{
    width: 40px;
    height: 100%;
    /* background-color: var(--secondary-background); */
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 20px;
    color: var(--primary-text);
}

.search-icon:hover{
    cursor: pointer;
    transform: translate(0, 0);
    box-shadow: none;
}

