.contractor-preview-container {
    background-color: whitesmoke;
    padding: 2em;
    margin-bottom: 0;
    border-bottom: 1px solid var(--tertiary-border);
    width: 30vw;
    min-width: 25em;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.profile-photo-preview {
    width: 7em;
    margin-right: 10px;
}

.contractor-preview-image-placeholder {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: var(--secondary-background);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.profile-info-preview {
    flex: 1; /* Take remaining space */
}

.preview-body-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 8em;
}

.preview-contractor-name {
    font-weight: 500;
    color: var(--primary-text);
    margin-bottom: 0.5em;
}

.preview-reviews-tag {
    font-size: 11px;
}