.login-form {
    background-color: var(--primary-background);
    padding-bottom: 2em;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    border-radius: 10px;
    color: black;
    margin-top: 50%;
    font-size: 20px;
    width: 15em;
}

.login-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.email-password {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.login-input {
    margin-bottom: 1em;
    height: 2em;
    width: 15em;
}

.homekeeper-login {
    margin-bottom: 1em;
    padding-bottom: 1em;
    text-align: center;
    width: 10em;
    border-bottom: 1px solid var(--tertiary-border);
}

.login-submit {
    width: 11em;
    height: 3em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

.login-button {
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.close-login {
    display: flex;
    justify-content: flex-end;
}