.contractor-section-container{
    height: 100%;
    width: 100%;
    background-color: var(--secondary-background);
    padding-bottom: 1em;
}

.contractor-section-content{
    height: 100%;
    width: 80%;
    padding-top: 2em;
    margin: 0 auto;
    position: relative;
}

.contractor-section-content h2{
    padding: 20px 0;
}

.contractor-section-index{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
    overflow-x: auto;
    padding: 2em;
    margin: 1em;
    border-radius: 8px;
    background-color: var(--primary-background);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contractor-section-index > h3 {
    margin-right: 50px;
}

.contractor-section-index > div {
    flex: 1;
}

.contractor-section-index h3{
    width: 120px;
    flex-shrink: 0;
}

.featured-contractors {
    font-size: 40px;
    font-weight: 500;
    text-align: left;
    margin-left: 0.5em;
    margin-top: 1em;
    margin-bottom: 1em;
}