.all-appts {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.appts-heading {
    padding: 1em;
    font-weight: 600;
    /* background-color: #e0e0e0; */
    width: 50em;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 1em;
}

.appts-heading > p {
    font-size: 25px;
    color: var(--primary-black);
}