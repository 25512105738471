.update-calender-container {
    position: sticky;
    z-index: 1;
    margin-top: 3em;
    height: 70%;
    background-color: var(--secondary-background);
    padding: 40px;
    border: 2px solid var(--primary-border);
    border-radius: 8px;
}

.update-calendar-buttons>button {
    margin-right: 1em;
}


/* date style */
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day:hover {
    background-color: orange;
    color: white;
}

/* Time Picker Style */
.react-datepicker__time-box {
    text-align: center;
}

.react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    background-color: orange !important;
    color: white;
}

.react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: orange !important;
    color: white;
}