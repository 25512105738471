.dropdown {
    position: absolute;
    margin-top: 2em;
    transform: translateX(calc(-100% + 1.5em));
    /* border: 1px solid rgb(190, 190, 190); */
    box-shadow: 3px 5px 5px rgb(190, 190, 190);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    background-color: white;
    filter: brightness(1.02);
    z-index: 3;
}

.dropdown li {
    padding: 0.7em 1em;
    cursor: pointer;
    border-radius: 7px 7px 0 0;
}

.dropdown li:first-child {
    border-radius: 7px 7px 0 0;
}

.dropdown li:last-child {
    border-radius: 0 0 7px 7px;
}

.dropdown li:hover {
    background-color: whitesmoke;
}